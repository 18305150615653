import React, { useEffect, useState } from 'react';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import CombineErrorMessage from 'components/CombineError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

const AvailableCapital = ({ isNewData, isOldData }) => {
  const [hasError, setHasError] = useState(false);
  const { errors, touched } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return (
      (errors.availableCapitalIn6Months && touched.availableCapitalIn6Months) ||
      (errors.availableCapitalNow && touched.availableCapitalNow)
    );
  };

  const onHandleKey = (event) => {
    if (event.key === '.' || event.key === 'e') {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Card className="mt-4">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields.</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Available Capital</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>
          <Label>How much capital do you have in this account to invest in mortgages?</Label>

          <Row className="input-spacing">
            <Col>
              <Field
                size="lg"
                id="capitalNow"
                name="availableCapitalNow"
                type="number"
                component={FormikInputField}
                label="Now"
                groupcanadacurrency="true"
                isError={false}
                step="0.01"
                onKeyDown={onHandleKey}
              />
            </Col>
            <Col>
              <Field
                size="lg"
                id="capitalInMonths"
                name="availableCapitalIn6Months"
                type="number"
                component={FormikInputField}
                label="In 6 Months"
                groupcanadacurrency="true"
                isError={false}
                step="any"
                onKeyDown={onHandleKey}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AvailableCapital;
