import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import BenifiMembers from '../../common/BenifiMembers';
import { v4 as uuidv4 } from 'uuid';
import MemberBenefiModal from '../../common/MemberBenefiModal';
import { GET_JOINT_MEMBERS } from 'pages/Investors/components/InvestorDetails/components/Accounts/components/Beneficiaries/GraphQL';
import { useLazyQuery } from '@apollo/client';
import { getUpdatedMembers } from 'pages/Profile/Helper';

const Joint = ({
  jointMembers,
  updateJointMember,
  userPersonalDetails,
  isNewForm,
  isNewDataForm,
  isNewData
}) => {
  const [state, setState] = useState({
    initialValues: {},
    memberModal: false,
    editMemberData: ''
  });
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const [fetchJointMembers, { data: fetchedJointMembers, refetch }] = useLazyQuery(
    GET_JOINT_MEMBERS,
    {
      variables: {
        userId: Number(userPersonalDetails.id),
        investorAccountId: values.id
      }
    }
  );

  const allJointsMembers = fetchedJointMembers?.getJointMembers?.jointMembers
    ? fetchedJointMembers?.getJointMembers?.jointMembers
    : [];

  const { memberModal, editMemberData } = state;

  useEffect(() => {
    if (values?.id) {
      fetchJointMembers();
    }
  }, []);

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return errors.isJointMember && touched.isJointMember;
  };

  const getJointMembers = (data) => {
    if (data.id) {
      const excludeOther = jointMembers.filter((item) => item.id !== data.id);
      const matchedJoint = jointMembers.find((item) => item.id === data.id);
      setState((pre) => ({
        ...pre,
        memberModal: false,
        editMemberData: ''
      }));
      updateJointMember([...excludeOther, { ...matchedJoint, ...data, id: data.id }]);
    } else {
      const read = { ...data, id: uuidv4() };
      updateJointMember([...jointMembers, { ...read }]);
      setState((pre) => ({
        ...pre,
        memberModal: false,
        editMemberData: ''
      }));
    }
  };

  const onEditJointMember = (id) => {
    const find = [...allJointsMembers, ...jointMembers].filter((item) => item.id === id);
    setState((pre) => ({
      ...pre,
      editMemberData: find[0],
      memberModal: true
    }));
  };

  const onRemoveJointMember = (id) => {
    const find = jointMembers.filter((item) => item.id !== id);
    if (!find?.length) {
      setFieldValue('isJointMember', false);
    }
    updateJointMember([...find]);
  };

  const onAddMember = () => {
    setState((pre) => ({
      ...pre,
      memberModal: true
    }));
  };

  const onCloseJointMemberModal = (flag = false) => {
    const allData = jointMembers.length || allJointsMembers.length;
    if (allData && !flag) {
      setFieldValue('isJointMember', true);
    } else {
      setFieldValue('isJointMember', flag);
    }

    setState((pre) => ({
      ...pre,
      memberModal: false,
      editMemberData: ''
    }));
  };

  const members = isNewForm ? [...jointMembers] : [...allJointsMembers, ...jointMembers];

  const updatedMembers = getUpdatedMembers(members, isNewDataForm);

  return (
    <div className="mt-3">
      {memberModal && (
        <MemberBenefiModal
          title="Additional Joint Member"
          editTitle="Edit Joint Member"
          open={memberModal}
          close={onCloseJointMemberModal}
          getData={getJointMembers}
          values={editMemberData}
          isJointMember
          refetch={refetch}
        />
      )}

      <BenifiMembers
        title="Joint Members"
        addButtonTitle="Add Member"
        members={[...updatedMembers]}
        onEdit={onEditJointMember}
        onRemove={onRemoveJointMember}
        onAddMember={onAddMember}
        refetch={refetch}
        isJoint
        isNewData={isNewData}
        formKey={{
          firstName: 'jointLegalName',
          lastName: 'jointLastName',
          relation: 'jointPosition',
          phoneNumber: 'jointPhoneNo'
        }}
      />
    </div>
  );
};

export default Joint;
