import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { getAccountInitialValue, accountTypes, otherFieldValidation } from 'pages/Profile/common';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import Trust from './components/Trust';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import Beneficiaries from './common/Beneficiaries';
import LendingKnowledge from './common/LendingKnowledge';
import Income from './common/Income';
import AvailableCapital from './common/AvailableCapital';
import InvestmentGoal from './common/InvestmentGoal';
import Corporation from './components/Corporation';
import Joint from './components/Joint';
import {
  trustAccountValidation,
  corporateAccountValidation,
  jointAccountValidation,
  individualAccountValidation
} from '../../../../common/index';
import { useMutation, useQuery } from '@apollo/client';
import {
  TRUST_ACCOUNT_MUTATION,
  ADD_BENEFICIARIES,
  ADD_CORPORATE_ACCOUNT,
  JOINT_ACCOUNT_MUTATION,
  INDIVIDUAL_ACCOUNT_MUTATION,
  ADD_DIRECTORS,
  FETCH_ACCOUNTS,
  UPDATE_INDIVIDUAL_ACCOUNT,
  UPDATE_TRUST_ACCOUNT,
  UPDATE_CORPORATION_ACCOUNT,
  UPDATE_JOINT_ACCOUNT,
  ADD_JOINT_MEMBER,
  ADD_TRUST_MEMBER
} from './AccountGraphQL';
import { getUser } from 'utils/UserDetails';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import AccountsUI from 'pages/Profile/common/Accounts';
import { APPROVE_USER_BY_ADMIN } from 'pages/Profile/common/Mutation';
import { toast } from 'react-toastify';
import ErrorScroll from 'components/ErrorScroll';
import AccountTypes from './AccountTypes';
import PageLoader from 'components/PageLoader';
import Loader from 'components/Loader';
import { checkAllocationSumOfIncome, updateAccountDetails } from './Helper';
import NewDataForm from './components/NewDataForm';

let init = { accountType: '' };
export const selfDirector = (user) => ({
  beneficiaryLegalName: user.firstName,
  beneficiaryLastName: user.lastName,
  beneficiaryRelationAccount: 'President'
});

const Accounts = ({ updateContent }) => {
  const user = getUser();
  const { userPersonalDetails, checkAcountUI, investorKey, setInvestorKey } = useOutletContext();
  const [state, setState] = useState({
    initialValues: init,
    validation: trustAccountValidation,
    beneficiaries: [],
    directors: [
      {
        ...selfDirector(user),
        beneficiaryPhoneNo: userPersonalDetails?.phoneNumber,
        isSelf: true,
        id: Math.random().toString(36)
      }
    ],
    jointMembers: [],
    trustMembers: [],
    accountData: null
  });
  const { id } = useParams();

  const { pathname } = useLocation();
  const {
    data: InvestorAccounts,
    refetch: refetchAccounts,
    loading
  } = useQuery(FETCH_ACCOUNTS, {
    variables: {
      userId: parseInt(id || user.id)
    }
  });
  const [addTrust, { loading: trustLoading }] = useMutation(TRUST_ACCOUNT_MUTATION);
  const [updateTrustAccount, { loading: trustUpdateLoading }] = useMutation(UPDATE_TRUST_ACCOUNT);
  const [addCorporation, { loading: corporateLoading }] = useMutation(ADD_CORPORATE_ACCOUNT);
  const [UpdateCorporation, { loading: updateCorpLoading }] = useMutation(
    UPDATE_CORPORATION_ACCOUNT
  );
  const [addBeneficiary] = useMutation(ADD_BENEFICIARIES);
  const [addDirectors] = useMutation(ADD_DIRECTORS);
  const [addJointMember] = useMutation(ADD_JOINT_MEMBER);
  const [addTrustMember] = useMutation(ADD_TRUST_MEMBER);
  const [addJoint, { loading: jointLoading }] = useMutation(JOINT_ACCOUNT_MUTATION);
  const [updateJoint, { loading: updateJointLoading }] = useMutation(UPDATE_JOINT_ACCOUNT);
  const [addIndividual, { loading: individualLoading }] = useMutation(INDIVIDUAL_ACCOUNT_MUTATION);
  const [updateIndividual, { loading: updateIndvLoading }] = useMutation(UPDATE_INDIVIDUAL_ACCOUNT);
  const [approveSection, { loading: approveLoading }] = useMutation(APPROVE_USER_BY_ADMIN);

  const {
    initialValues,
    validation,
    beneficiaries,
    directors,
    accountData,
    trustMembers,
    jointMembers
  } = state;

  const updateAccountValidation = (Id) => {
    if (Id === 'joint') {
      setState((pre) => ({
        ...pre,
        validation: jointAccountValidation
      }));
    }
    if (Id === 'trust') {
      setState((pre) => ({
        ...pre,
        validation: trustAccountValidation
      }));
    }
    if (Id === 'corporation') {
      setState((pre) => ({
        ...pre,
        validation: corporateAccountValidation
      }));
    }
    if (Id === 'individual') {
      setState((pre) => ({
        ...pre,
        validation: individualAccountValidation
      }));
    }
  };

  //On Form Submit
  const onSubmit = async (data, action) => {
    const { setFieldError } = action;
    try {
      if (data.website) {
        data.website = data.website.trim();
      }
      const formObj = { ...data };
      if (!beneficiaries.length && formObj.isBeneficiaries && !formObj?.id)
        return setFieldError('isBeneficiaries', 'Please add beneficiaries');

      const checkAllocation = checkAllocationSumOfIncome(formObj);
      if (!checkAllocation) {
        const ele = document.querySelector('#errorScroll');
        return ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      formObj.employment = `${formObj.employment}`;
      formObj.interest = `${formObj.interest}`;
      formObj.capitalGains = `${formObj.capitalGains}`;
      formObj.otherIncome = `${formObj.otherIncome}`;
      formObj.availableCapitalIn6Months = Number(formObj.availableCapitalIn6Months);
      formObj.availableCapitalNow = Number(formObj.availableCapitalNow);

      delete formObj.userId;
      delete formObj.isCapitalUpdated;
      if (!formObj?.id && !trustMembers.length && formObj.isTrustMember)
        return setFieldError('isTrustMember', 'Please add members');
      if (formObj?.id) {
        await updateAccountDetails(
          formObj,
          {
            individual: updateIndividual,
            trust: updateTrustAccount,
            corporation: UpdateCorporation,
            joint: updateJoint,
            refetchAccounts: refetchAccounts
          },
          initialValues,
          pathname,
          user
        );
        getBenifi({
          userId: user.id,
          accountTypeId: formObj.accountTypeId,
          investorAccountId: formObj?.id
        });
        getJointMembers({
          userId: user.id,
          accountTypeId: formObj.accountTypeId,
          investorAccountId: formObj?.id
        });
        getTrustMembers({
          userId: user.id,
          accountTypeId: formObj.accountTypeId,
          investorAccountId: formObj?.id
        });
        if (formObj.accountTypeId === 2) {
          onLoopDirectors({
            userId: user.id,
            accountTypeId: formObj.accountTypeId,
            investorAccountId: formObj?.id
          });
        }

        setState((pre) => ({
          ...pre,
          accountData: false,
          beneficiaries: [],
          directors: [],
          trustMembers: []
        }));
        return;
      }
      delete formObj.accountType;
      if (data.accountType === 'trust') {
        formObj.accountNumber = `${formObj.accountNumber}`;
        const { data: result } = await addTrust({
          variables: { userId: parseInt(user.id), trustAccountInput: formObj }
        });
        const investorAccountId = result?.addTrustAccount?.investorAccount?.id;
        getBenifi({
          userId: user.id,
          accountTypeId: data.accountTypeId,
          investorAccountId: investorAccountId
        });
        getTrustMembers({
          userId: user.id,
          accountTypeId: data.accountTypeId,
          investorAccountId: investorAccountId
        });
        toast.success(result?.addTrustAccount.message);
      }
      if (data.accountType === 'corporation') {
        if (formObj.website) {
          formObj.website =
            formObj.website.includes('https') || formObj.website.includes('http')
              ? formObj.website
              : `https://${formObj.website}`;
        }

        delete formObj.trustName;

        const { data: result } = await addCorporation({
          variables: { userId: parseInt(user.id), corporateAccountInput: formObj }
        });
        const investorAccountId = result?.addCorporateAccountDetails?.investorAccount?.id;
        toast.success(result?.addCorporateAccountDetails.message);
        onLoopDirectors({
          userId: user.id,
          accountTypeId: data.accountTypeId,
          investorAccountId: investorAccountId
        });
        getBenifi({
          userId: user.id,
          accountTypeId: data.accountTypeId,
          investorAccountId: investorAccountId
        });
      }
      if (data.accountType === 'joint') {
        const { data: result } = await addJoint({
          variables: {
            userId: user.id,
            jointAccountInput: formObj
          }
        });
        const investorAccountId = result?.addJointMemberAccount?.investorAccount?.id;
        getBenifi({
          userId: user.id,
          accountTypeId: data.accountTypeId,
          investorAccountId: investorAccountId
        });
        getJointMembers({
          userId: user.id,
          accountTypeId: data.accountTypeId,
          investorAccountId: investorAccountId
        });
        toast.success(result?.addJointMemberAccount.message);
      }
      if (data.accountType === 'individual') {
        const { data: result } = await addIndividual({
          variables: {
            userId: user.id,
            individualAccountInput: formObj
          }
        });
        toast.success(result?.addIndividualMemberAccount.message);
        const investorAccountId = result?.addIndividualMemberAccount?.individualMemberDetails?.id;
        getBenifi({
          userId: user.id,
          accountTypeId: data.accountTypeId,
          investorAccountId: investorAccountId
        });
      }
      setState((pre) => ({
        ...pre,
        accountData: false,
        beneficiaries: [],
        directors: [],
        trustMembers: []
      }));
      refetchAccounts();
    } catch (error) {
      toast.error(error.message);
    }
  };

  //Get Benificiries
  const getBenifi = (obj) => {
    for (let ben = 0; ben < beneficiaries.length; ben++) {
      onSubmitBenifi({ ...obj, ...beneficiaries[ben] });
    }
  };

  //Get Joint Members
  const getJointMembers = (obj) => {
    for (let i = 0; i < jointMembers.length; i++) {
      onSubmitJointMember({ ...obj, ...jointMembers[i] });
    }
  };

  //Get Trust Members
  const getTrustMembers = (obj) => {
    for (let i = 0; i < trustMembers.length; i++) {
      onSubmitTrustMember({ ...obj, ...trustMembers[i] });
    }
  };

  const onSubmitBenifi = async (data) => {
    try {
      const result = await addBeneficiary({ variables: data });
    } catch (error) {
      console.log(error);
    }
  };

  const onLoopDirectors = (obj) => {
    for (let i = 0; i < directors.length; i++) {
      onSubmitDirectors({ ...obj, ...directors[i] });
    }
  };

  const onSubmitDirectors = async (obj) => {
    const {
      userId,
      accountTypeId,
      beneficiaryLegalName,
      beneficiaryLastName,
      beneficiaryRelationAccount,
      beneficiaryPhoneNo,
      investorAccountId
    } = obj;
    let formObj = {
      userId,
      accountTypeId,
      directorLegalName: beneficiaryLegalName,
      directorLastName: beneficiaryLastName,
      directorPosition: beneficiaryRelationAccount,
      directorPhoneNo: beneficiaryPhoneNo,
      investorAccountId: investorAccountId
    };
    try {
      await addDirectors({ variables: formObj });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitJointMember = async (obj) => {
    const {
      userId,
      accountTypeId,
      beneficiaryLegalName,
      beneficiaryLastName,
      beneficiaryRelationAccount,
      beneficiaryPhoneNo,
      investorAccountId
    } = obj;
    let formObj = {
      userId,
      accountTypeId,
      jointLegalName: beneficiaryLegalName,
      jointLastName: beneficiaryLastName,
      jointRelationAccount: beneficiaryRelationAccount,
      jointPhoneNo: beneficiaryPhoneNo,
      investorAccountId: investorAccountId
    };
    try {
      await addJointMember({ variables: formObj });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitTrustMember = async (obj) => {
    const {
      userId,
      accountTypeId,
      beneficiaryLegalName,
      beneficiaryLastName,
      beneficiaryRelationAccount,
      beneficiaryPhoneNo,
      investorAccountId
    } = obj;
    let formObj = {
      userId,
      accountTypeId,
      trustLegalName: beneficiaryLegalName,
      trustLastName: beneficiaryLastName,
      trustRelationAccount: beneficiaryRelationAccount,
      trustPhoneNo: beneficiaryPhoneNo,
      investorAccountId: investorAccountId
    };
    try {
      await addTrustMember({ variables: formObj });
    } catch (error) {
      console.log(error);
    }
  };

  const updateBenifi = (ben) => {
    setState((pre) => ({ ...pre, beneficiaries: ben }));
  };

  const updateDirectors = (directrs) => {
    setState((pre) => ({ ...pre, directors: directrs }));
  };

  const updateJointMember = (member) => {
    setState((pre) => ({ ...pre, jointMembers: member }));
  };

  const updateTrustMembers = (members) => {
    setState((pre) => ({ ...pre, trustMembers: members }));
  };

  const accountFormEnable = (type, data) => {
    let formData = { ...data };
    if (type === 'corporation') {
      const copyCorpDetails = { ...data.corporate_member };
      delete copyCorpDetails.id;
      formData = { ...formData, ...copyCorpDetails };
    }
    if (type === 'trust') {
      formData = {
        ...formData
      };
    }
    setState((pre) => ({
      ...pre,
      initialValues: {
        accountType: type,
        ...formData
      },
      accountData: { ...formData }
    }));
    checkAcountUI(formData);
    updateAccountValidation(type);
  };

  const addNewAccount = () => {
    setState((pre) => ({
      ...pre,
      accountData: true,
      initialValues: init
    }));
  };

  if (loading) {
    return (
      <Card>
        <CardBody>
          <PageLoader />
        </CardBody>
      </Card>
    );
  }

  if (InvestorAccounts?.getInvestorAccounts?.length && !accountData) {
    return (
      <AccountsUI
        data={InvestorAccounts.getInvestorAccounts}
        accountFormEnable={accountFormEnable}
        addNewAccount={addNewAccount}
        updateContent={updateContent}
        userPersonalDetails={userPersonalDetails}
        approveSection={approveSection}
        approveLoading={approveLoading}
        refetch={refetchAccounts}
      />
    );
  }

  const onHandleAccountType = (account, setFieldValue, touched, setTouched, setErrors) => {
    setErrors({});
    setTouched({
      ...touched,
      employment: false,
      interest: false,
      capitalGains: false,
      otherIncome: false
    });
    setFieldValue('accountType', account);
    setState((pre) => ({
      ...pre,
      initialValues: {
        accountType: account
      }
    }));
    setTimeout(() => {
      setState((pre) => ({
        ...pre,
        initialValues: { ...pre.initialValues, ...getAccountInitialValue(account) }
      }));
    }, 500);
    updateAccountValidation(account);
  };

  const onUpdateValidation = (flag, accountType) => {
    if (flag) {
      setState((pre) => ({
        ...pre,
        validation: { ...pre.validation, ...otherFieldValidation }
      }));
    } else {
      updateAccountValidation(accountType);
    }
  };

  const renderSaveButton = () => {
    return trustLoading || corporateLoading || jointLoading || individualLoading ? (
      <Loader />
    ) : (
      <Button type="submit" size="lg" color="primary">
        Save and continue
      </Button>
    );
  };

  const renderSubmitActionButtons = () => {
    if (
      (pathname.includes('approval') || pathname.includes('user')) &&
      initialValues &&
      ((initialValues.isOld && initialValues.isApproved) ||
        (!initialValues.isOld && !initialValues.isApproved))
    ) {
      return (
        <Button
          color="secondary"
          type="button"
          onClick={() =>
            setState((pre) => ({
              ...pre,
              accountData: null
            }))
          }>
          Back to Account
        </Button>
      );
    }
    if (pathname.includes('complete-profile') || pathname.includes('profile')) {
      return renderSaveButton();
    }
  };

  const renderNewDataForm = () => {
    if (
      pathname.includes('approval') &&
      userPersonalDetails.isUnderReviewed &&
      ((!accountData?.isApproved && accountData?.isOld) || accountData?.isRemove)
    ) {
      return (
        <Col>
          <NewDataForm
            userPersonalDetails={userPersonalDetails}
            accountData={accountData}
            setState={setState}
            investorKey={investorKey}
            setInvestorKey={setInvestorKey}
          />
        </Col>
      );
    }
  };

  return (
    <Row>
      <Col>
        <Formik
          initialTouched={{ zip: true }}
          initialValues={initialValues}
          validationSchema={
            pathname.includes('approval') ? Yup.object().shape({}) : Yup.object(validation)
          }
          onSubmit={onSubmit}
          enableReinitialize={true}
          validateOnMount={true}>
          {({ handleSubmit, values, setFieldValue, touched, setTouched, setErrors }) => (
            <form onSubmit={handleSubmit}>
              <ErrorScroll />
              <AccountTypes>
                <div className="input-spacing">
                  <Label>What is the type of this account ?</Label>
                  <Row className="w-50">
                    {accountTypes.map((item) => (
                      <Col md={6} lg={6} className="mb-1" key={item}>
                        <Field
                          size="lg"
                          key={item}
                          id={item}
                          name="accountType"
                          className="text-capitalize"
                          label={item}
                          value={values.accountType}
                          onClick={() =>
                            onHandleAccountType(item, setFieldValue, touched, setTouched, setErrors)
                          }
                          component={FormikRadioboxField}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </AccountTypes>
              {values.accountType === 'trust' && (
                <Trust
                  userPersonalDetails={userPersonalDetails}
                  updateTrustMembers={updateTrustMembers}
                  trustMembers={trustMembers}
                />
              )}
              {values.accountType === 'corporation' && (
                <Corporation
                  updateDirectors={updateDirectors}
                  directors={directors}
                  userPersonalDetails={userPersonalDetails}
                />
              )}
              {values.accountType === 'joint' && (
                <Joint
                  userPersonalDetails={userPersonalDetails}
                  updateJointMember={updateJointMember}
                  jointMembers={jointMembers}
                />
              )}
              {values.accountType && (
                <>
                  <Beneficiaries
                    userPersonalDetails={userPersonalDetails}
                    updateBenifi={updateBenifi}
                    beneficiaries={beneficiaries}
                  />
                  <LendingKnowledge />
                  <Income onUpdateValidation={onUpdateValidation} />
                  <AvailableCapital />
                  <InvestmentGoal />
                  <div className="mt-4 text-end">{renderSubmitActionButtons()}</div>
                </>
              )}
            </form>
          )}
        </Formik>
      </Col>
      {renderNewDataForm()}
    </Row>
  );
};

export default Accounts;
