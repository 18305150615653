import React, { useState, useEffect } from 'react';
import MemberBenefiModal from '../../common/MemberBenefiModal';
import TrsutInfo from './components/TrustInfo';
import { v4 as uuidv4 } from 'uuid';
import BenifiMembers from '../../common/BenifiMembers';
import { useFormikContext } from 'formik';
import { GET_TRUST_MEMBERS } from 'pages/Investors/components/InvestorDetails/components/Accounts/components/Beneficiaries/GraphQL';
import { useLazyQuery } from '@apollo/client';
import { getUpdatedMembers } from 'pages/Profile/Helper';

const Trust = ({
  updateTrustMembers,
  userPersonalDetails,
  trustMembers,
  isNewForm,
  isNewDataForm,
  isNewData
}) => {
  const [state, setState] = useState({
    trustModal: false,
    editMemberData: '',
    beneficiaries: [],
    benifiModal: false,
    editBenifiData: ''
  });
  const [hasError, setHasError] = useState(false);

  const { touched, errors, values, setFieldValue } = useFormikContext();

  const [fetchTrustMembers, { data: fetchedTrustMembers, refetch }] = useLazyQuery(
    GET_TRUST_MEMBERS,
    {
      variables: {
        userId: Number(userPersonalDetails.id),
        investorAccountId: values.id
      }
    }
  );

  const allTrustsMembers = fetchedTrustMembers?.getTrustMembers?.trustMembers
    ? fetchedTrustMembers?.getTrustMembers?.trustMembers
    : [];

  const { trustModal, editMemberData } = state;

  useEffect(() => {
    if (values?.id) {
      fetchTrustMembers();
    }
  }, []);

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  const checkError = () => {
    return errors.isTrustMember && touched.isTrustMember;
  };

  const getTrustMember = (data) => {
    if (data.id) {
      const exclude = trustMembers?.filter((item) => item.id !== data.id);
      const matchMember = trustMembers?.find((item) => item.id === data.id);
      setState((pre) => ({
        ...pre,
        trustModal: false,
        editMemberData: ''
      }));
      updateTrustMembers([...exclude, { ...matchMember, ...data, id: data.id }]);
    } else {
      const read = { ...data, id: uuidv4() };
      setState((pre) => ({
        ...pre,
        trustModal: false,
        editMemberData: ''
      }));
      updateTrustMembers([...trustMembers, { ...read }]);
    }
  };

  const onEditTrustMember = (id) => {
    const find = [...allTrustsMembers, ...trustMembers].filter((item) => item.id === id);
    setState((pre) => ({
      ...pre,
      editMemberData: find[0],
      trustModal: true
    }));
  };

  const onRemoveTrustMember = (id) => {
    const find = trustMembers.filter((item) => item.id !== id);
    if (!find?.length) {
      setFieldValue('isTrustMember', false);
    }
    updateTrustMembers([...find]);
  };

  const onAddMember = () => {
    setState((pre) => ({
      ...pre,
      trustModal: true
    }));
  };

  const onHandleClick = (e) => {
    const { value } = e.target;
    setState((pre) => ({
      ...pre,
      trustModal: value === 'true' ? true : false
    }));
  };

  const onCloseTrustMemberModal = (flag = false) => {
    const allData = trustMembers.length || allTrustsMembers.length;
    if (allData && !flag) {
      setFieldValue('isTrustMember', true);
    } else {
      setFieldValue('isTrustMember', flag);
    }
    setState((pre) => ({
      ...pre,
      trustModal: false,
      editMemberData: ''
    }));
  };

  const members = isNewForm ? [...trustMembers] : [...allTrustsMembers, ...trustMembers];

  const updatedMembers = getUpdatedMembers(members, isNewDataForm);

  return (
    <div>
      <TrsutInfo onHandleClick={onHandleClick} />
      {trustModal && (
        <MemberBenefiModal
          title="Additional Trust Member"
          editTitle="Edit Trust Member"
          open={trustModal}
          close={onCloseTrustMemberModal}
          getData={getTrustMember}
          values={editMemberData}
          isTrustMember
          refetch={refetch}
        />
      )}
      {updatedMembers.length ? (
        <BenifiMembers
          title="Trust Members"
          addButtonTitle="Add Member"
          members={[...updatedMembers]}
          onEdit={onEditTrustMember}
          onRemove={onRemoveTrustMember}
          onAddMember={onAddMember}
          refetch={refetch}
          isTrust
          isNewData={isNewData}
          formKey={{
            firstName: 'trustLegalName',
            lastName: 'trustLastName',
            relation: 'trustRelationAccount',
            phoneNumber: 'trustPhoneNo'
          }}
        />
      ) : null}
    </div>
  );
};

export default Trust;
