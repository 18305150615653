import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FormikRadioField from 'components/common/Fields/FormikRadioField';
import Information from '../Information';
import CombineErrorMessage from 'components/CombineError';
import FormikReactSelect from 'components/common/Fields/FormikReactSelect';
import { useQuery } from '@apollo/client';
import { find } from 'lodash';
import { GET_RECOMMENDED_INVESTOR } from 'pages/FindMortgageAdmin/GraphQL';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';

const Licensing = ({ updateValidationFromLicense, isNewData, isOldData }) => {
  const [hasError, setHasError] = useState(false);
  const [infoFirst, setInfoFirst] = useState(false);
  const [infoSecond, setInfoSecond] = useState(false);
  const [searchMortgage, setSearchMortgage] = useState('');
  const { pathname } = useLocation();

  const isProfile = pathname.includes('profile');
  const isCompleteProfile = pathname.includes('complete-profile');

  const { data } = useQuery(GET_RECOMMENDED_INVESTOR, {
    variables: {
      offset: 0,
      limit: 10,
      search: searchMortgage
    }
  });

  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();

  const answer = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  useEffect(() => {
    if (
      typeof values?.licensedFsra === 'boolean' &&
      typeof values?.workedWithMA === 'boolean' &&
      !values?.licensedFsra &&
      !values?.workedWithMA
    ) {
      setInfoFirst(true);
      setInfoSecond(false);
    }
    if (
      typeof values?.licensedFsra === 'boolean' &&
      typeof values?.workedWithMA === 'boolean' &&
      values?.licensedFsra &&
      !values?.workedWithMA
    ) {
      setInfoSecond(true);
      setInfoFirst(false);
    }
    if (
      typeof values?.licensedFsra === 'boolean' &&
      typeof values?.workedWithMA === 'boolean' &&
      values?.licensedFsra &&
      values?.workedWithMA
    ) {
      setInfoFirst(false);
      setInfoSecond(false);
    }

    if (typeof values?.workedWithMA === 'boolean' && values?.workedWithMA) {
      setInfoFirst(false);
      setInfoSecond(false);
    }

    if (values?.licensedFsra) {
      updateValidationFromLicense(true);
    }

    if (
      (!values?.workedWithMA && typeof values?.workedWithMA === 'boolean') ||
      (!values?.licensedFsra && typeof values?.licensedFsra === 'boolean')
    ) {
      updateValidationFromLicense(false);
    }
  }, [values?.licensedFsra, values?.workedWithMA]);

  useEffect(() => {
    const setError = checkIfError();
    setHasError(setError);
  }, [errors, touched]);

  const checkIfError = () => {
    return (
      (errors.licensedFsra && touched.licensedFsra) ||
      (errors.licenseNumber && touched.licenseNumber) ||
      (errors.workedWithMA && touched.workedWithMA) ||
      (errors.mortgageAdmin && touched.mortgageAdmin)
    );
  };

  const onInfoClose = () => {
    setInfoFirst(false);
    setInfoSecond(false);
  };

  const onChangeMortgageAdmin = (value) => {
    setSearchMortgage(value);
  };

  const onSelectMA = (values) => {
    setFieldValue('mortgageAdmin', values);
    setFieldValue('mortgageAdminId', values.id);
  };

  const mortgages = data?.getRecommendedMAsForInvestor?.maData
    ? data?.getRecommendedMAsForInvestor?.maData
    : [];

  const findSelectedMA = () => {
    let selected = find(mortgages, (item) => Number(item.id) === Number(values.mortgageAdminId));
    return selected;
  };

  const getName = (opt) => {
    if (opt.operatingName) return opt.operatingName;
    if (opt.businessName) return opt.businessName;
    return `${opt.firstName} ${opt.lastName}`;
  };

  const renderWorkWithMa = () => {
    if (isProfile && !isCompleteProfile) {
      return null;
    }
    return (
      <>
        <Col md={12} lg={12} className="input-spacing">
          <div>
            <Label>Do you work with a Mortgage Administrator?</Label>
            <div className="d-flex">
              {answer.map(({ label, value }, index) => {
                return (
                  <div key={label} className={index === 1 ? 'ms-3' : ''}>
                    <Field
                      name="workedWithMA"
                      type="radio"
                      label={label}
                      value={value}
                      checked={index === 1 ? !values.workedWithMA : values.workedWithMA}
                      component={FormikRadioField}
                      isError={false}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
        {values.workedWithMA ? (
          <Col className="input-spacing" md={6} lg={6}>
            <Field
              size="lg"
              name="mortgageAdmin"
              type="text"
              component={FormikReactSelect}
              options={mortgages}
              value={findSelectedMA()}
              onChange={onChangeMortgageAdmin}
              onSelect={onSelectMA}
              onBlur={() => setFieldTouched('mortgageAdmin')}
              getOptionLabel={(option) => getName(option)}
              getOptionValue={(option) => option.id}
              label="Mortgage Administrator"
              style={
                errors?.mortgageAdminId && touched.mortgageAdmin
                  ? { border: '1px solid #EA6666' }
                  : {}
              }
            />
          </Col>
        ) : null}
      </>
    );
  };

  return (
    <div>
      <Card>
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">Licensing and Administration</h3>
            {isOldData ? <p className="font-weight-bold">Old Data</p> : null}
            {isNewData ? (
              <FontAwesomeIcon className="new-data-icon" icon={faClockRotateLeft} />
            ) : null}
          </div>
          <Row>
            <Col md={12} lg={12} className="input-spacing">
              <Label>Are you licensed under FSRA?</Label>

              <div className="d-flex">
                {answer.map(({ label, value }, index) => {
                  return (
                    <div key={label} className={index === 1 ? 'ms-3' : ''}>
                      <Field
                        name="licensedFsra"
                        type="radio"
                        label={label}
                        value={value}
                        checked={index === 1 ? !values.licensedFsra : values.licensedFsra}
                        component={FormikRadioField}
                        isError={false}
                      />
                    </div>
                  );
                })}
              </div>
            </Col>

            {values.licensedFsra ? (
              <Col className="input-spacing" md={6} lg={6}>
                <Field
                  size="lg"
                  id="licenseNumber"
                  name="licenseNumber"
                  type="text"
                  component={FormikInputField}
                  label="FSRA License Number"
                />
              </Col>
            ) : null}
            {renderWorkWithMa()}
          </Row>
        </CardBody>
      </Card>
      {infoFirst || infoSecond ? <Information first={infoFirst} onClose={onInfoClose} /> : null}
    </div>
  );
};

export default Licensing;
